@import '~antd/dist/antd.less';

:root {
  // Primary
  --primary-dark: @primary-dark;
  --primary: @primary;
  --primary-light: @primary-light;

  // Secondary
  --secondary-dark: @secondary-dark;
  --secondary: @secondary;
  --secondary-light: @secondary-light;

  --white: @white-color;

  // Text
  --text-dark: @text-dark;
  --text-dark-1: @text-dark-1;
  --text-dark-2: @text-dark-2;
  --text-dark-3: @text-dark-3;
  --text-dark-4: @text-dark-4;

  --text-light: @text-light;
  --text-light-1: @text-light-1;
  --text-light-2: @text-light-2;
  --text-light-3: @text-light-3;
  --text-light-4: @text-light-4;

  // Background
  --bg-dark: @bg-dark;
  --bg-dark-1: @bg-dark-1;
  --bg-dark-2: @bg-dark-2;
  --bg-dark-3: @bg-dark-3;
  --bg-dark-4: @bg-dark-4;

  --bg-light: @bg-light;
  --bg-light-1: @bg-light-1;
  --bg-light-2: @bg-light-2;
  --bg-light-3: @bg-light-3;
  --bg-light-4: @bg-light-4;

  // Custom Color
  // Red
  --red: @red;
  --red-1: @red-1;
  --red-2: @red-2;
  --red-3: @red-3;
  --red-4: @red-4;
  // Blue
  --blue: @blue;
  --blue-1: @blue-1;
  --blue-2: @blue-2;
  --blue-3: @blue-3;
  --blue-4: @blue-4;
  // Green
  --green: @green;
  --green-1: @green-1;
  --green-2: @green-2;
  --green-3: @green-3;
  --green-4: @green-4;

  // Customizations
  --text-color: @text-dark-1;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

@primary-dark: #040624;@primary: #0A0D36;@primary-light: #2F3154;@secondary-dark: #2649C6;@secondary: #3860EE;@secondary-light: #6080F1;@white-color: #ffffff;@text-dark: #1C1C28;@text-dark-1: #28293D;@text-dark-2: #555770;@text-dark-3: #8E90A6;@text-dark-4: #C7C8D9;@text-light: #E3E4EB;@text-light-1: #EBEBF0;@text-light-2: #F2F2F5;@text-light-3: #FAFAFC;@text-light-4: #FFFFFF;@bg-dark: #1C1C28;@bg-dark-1: #28293D;@bg-dark-2: #555770;@bg-dark-3: #8E90A6;@bg-dark-4: #C7C8D9;@bg-light: #E3E4EB;@bg-light-1: #EBEBF0;@bg-light-2: #F2F2F5;@bg-light-3: #FAFAFC;@bg-light-4: #FFFFFF;@red: #E53535;@red-1: #FF3B3B;@red-2: #FF5C5C;@red-3: #FF8080;@red-4: #FFE5E5;@blue: #004FC4;@blue-1: #0063F7;@blue-2: #5B8DEF;@blue-3: #9DBFF9;@blue-4: #E5F0FF;@green: #05A660;@green-1: #06C270;@green-2: #39D98A;@green-3: #56EBA0;@green-4: #E3FFF1;@text-color: @text-dark-1;@primary-color: @primary;@secondary-color: @secondary;@font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;@border-radius-base: 4px;@btn-font-weight: 500;@btn-default-color: @primary-color;@btn-default-border: @primary-color;